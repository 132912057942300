
import { HeaderMain, HeaderParagraph,HeaderTitle } from './HeaderElements';
import { Container,Column } from './../Layout/layoutElement';
import Img from './../image/img';
import MainButton from './../button/MainButton';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../constants/defaultValues';

export default function Header () {
    const navigate = useNavigate()
    // const hadleregister = ()=>{
    //     navigate('https://staging-portal.icadpay.com/user/register')
    //     // window.location.href('' )
    //     // window.open('https://portal.icadpay.com/user/register', "_blank")
    
    // }

    const hadleregister = () => {

        // if (BASE_URL === "https://app-api.icadpay.com/api") {
        //     window.open('https://app-api.icadpay.com/api/user/register', "_blank");
        // } else {
        //     window.location.href = 'https://staging-portal.icadpay.com/user/register';
        // }
        navigate("/user/register");
    };
    
    return (
        <HeaderMain>
            <Container>
                <Column divide={2}>
                    <HeaderTitle>Make Swift & seamless payments</HeaderTitle>
                    <HeaderParagraph>We are your one-stop payment service for payment collections and disbursement with the widest choice of payment methods and schemes to support your organization and make transactions easier.</HeaderParagraph>
                    <MainButton text={`Register`} click={hadleregister} main={true} />
                </Column>
                <Column divide={2}>
                    <Img alt={'picture one'} />
                </Column>
            </Container>
        </HeaderMain>
    );
}